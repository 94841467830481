<template>
  <Layout :tituloPagina="'Network | Puntos de acceso'">
    <div class="row mb-4">
      <div class="col-lg-2 col-md-3 mb-3">
        <div class="input-group">
          <span class="input-group-text">Sitio</span>
          <select v-model="id_sitio" class="form-select" @change="refrescarAps()">
            <option :value="null">Seleccionar sitio</option>
            <option
              v-for="sitio in sitios"
              :value="sitio.id"
              :key="sitio.id"
            >
              {{ sitio.nombre }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-8 col-md-6 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarAps()
          "
          :datoini="dato"
          placeholder="Dato del punto de acceso"
        ></filtrador>
      </div>
      <div class="col-lg-2 col-md-3 col-3 text-right">
        <router-link :to="{ name: 'nuevoAp' }" class="btn btn-success">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table id="tblListaAP" class="table table-hover table-nowrap">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>SSID</th>
                <th style="width:200px">IP</th>
                <th>Sitio</th>
                <th>Estado</th>
                <th style="width: 100px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ap in aps" :key="ap.id">
                <td>
                  <router-link
                    :to="{
                      name: 'edicionAp',
                      params: { id: ap.id }
                    }"
                  >
                    {{ ap.nombre }}
                  </router-link>
                </td>
                <td>{{ ap.ssid }}</td>
                <td>{{ ap.ip }}</td>
                <td>{{ ap.sitio ? ap.sitio.nombre : 'Sin sitio' }}</td>
                <td>
                  <span 
                    class="badge bg-gradient font-size-13"
                    :class="{
                      'bg-success' : ap.estado == 'Funcional',
                      'bg-primary' : ap.estado == 'Reparación',
                      'bg-danger'  : ap.estado == 'Descompuesto'
                    }"
                  >
                    {{ap.estado}}
                  </span>
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <button class="dropdown-item info" @click="verPuntoAcceso(ap)">
                          <i class="mdi mdi-eye text-info"></i>
                          Ver
                        </button>
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'edicionAp',
                            params: { id: ap.id }
                          }"
                          class="dropdown-item warning"
                        >
                          <i class="mdi mdi-square-edit-outline text-warning"></i>
                          Editar
                        </router-link>
                      </li>
                      <li>
                        <button class="dropdown-item danger" @click="preguntaEliminarAp(ap)">
                          <i class="mdi mdi-trash-can-outline text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultimaPagina"
        :epp="porPagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          porPagina = $event,
          refrescarAps()
        "
      ></paginador>
    </div>

    <mdl-informacion-ap ref="mdlInfoAp"></mdl-informacion-ap>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import PuntoAccesosSrv from '@/services/PuntoAccesosSrv.js'
import SitioSrv from '@/services/SitioSrv.js'
import MdlInformacionAp from './MdlInformacionAp.vue'
import Swal from 'sweetalert2'
import API from '@/API.js'
export default {
  name: 'ListadoAps',
  components: {
    Layout,
    Paginador,
    Filtrador,
    MdlInformacionAp
  },
  data() {
    return {
      API: API,
      aps: [],
      baseUrl: window.location.origin,
      dato: '',
      pagina: 1,
      porPagina: parseInt(localStorage.getItem('argusblack.listadoAps.por_pagina') || 10),
      ultimaPagina: 1,
      sitios: [],
      id_sitio: null
    }
  },

  created: function() {
    var self = this

    // Carga de los querys
    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.pagina) self.pagina = parseInt(self.$route.query.pagina)
    if (self.$route.query.por_pagina) self.porPagina = parseInt(self.$route.query.por_pagina)
    if (self.$route.query.id_sitio) self.id_sitio = parseInt(self.$route.query.id_sitio)

    self.cargarSitios()
    // Carga inicial de los aps
    self.refrescarAps()
  },

  methods: {
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarAps()
    },

    cargarSitios: function() {
      var self = this

      SitioSrv.sitiosJSON().then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    
    preguntaEliminarAp: function(ap) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar punto de acceso",
        html: '¿Está seguro que quiere eliminar el punto de acceso <strong>'+ap.nombre+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          PuntoAccesosSrv.eliminar(ap.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarAps()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el punto de acceso'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarAps: function() {
      var self = this
      
      iu.spinner.mostrar('#tblListaAP')

      // Registro de los querys
      self.$router.replace({
        query: {
          dato: self.dato,
          pagina: self.pagina,
          por_pagina: self.porPagina,
          id_sitio: self.id_sitio
        }
      }).catch(error => {})

      let params = {
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.porPagina,
        todos: self.todos,
        id_sitio: self.id_sitio
      }

      PuntoAccesosSrv.puntosAccesoJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoAps.por_pagina', self.porPagina)
        let res = response.data
        self.aps = res.aps
        self.ultimaPagina = res.ultima_pagina
        self.btnPagFin = res.ultimaPagina
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los puntos de acceso'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblListaAP')
      })
    },

    verPuntoAcceso: function(ap) {
      this.$refs.mdlInfoAp.mostrar(ap)
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListaAP')
  }
}
</script>

<style scoped>

</style>