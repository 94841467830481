<template>
  <div class="modal fade" ref="mdlInformacionAP" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white"
          :class="{
            'bg-success' : punto_acceso.estado == 'Funcional',
            'bg-primary' : punto_acceso.estado == 'Reparación',
            'bg-danger'  : punto_acceso.estado == 'Descompuesto'
          }"
        >
          <div class="modal-title text-center">
            <strong style="font-size:30px">Información de {{punto_acceso.nombre}}</strong>
            <br>
            <span>Estado: {{punto_acceso.estado}}</span>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Marca</label>
              <span class="form-control">{{punto_acceso.marca}}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Modelo</label>
              <span class="form-control">{{punto_acceso.modelo}}</span>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Sitio</label>
              <span class="form-control">{{punto_acceso.sitio ? punto_acceso.sitio.nombre : ''}}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Router de administración</label>
              <span class="form-control">{{punto_acceso.router ? punto_acceso.router.nombre : ''}}</span>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Dirección IP</label>
              <span class="form-control">{{punto_acceso.ip}}</span>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>MAC del puerto WAN</label>
              <span class="form-control">{{punto_acceso.mac_wan}}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>MAC del puerto LAN</label>
              <span class="form-control">{{punto_acceso.mac_lan}}</span>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>SSID</label>
              <span class="form-control">{{punto_acceso.ssid}}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Contraseña inalambrica</label>
              <div class="input-group">
                <span class="form-control">{{!mostrar_clave_inalambrica ? '*****' : punto_acceso.clave_inalambrica}}</span>
                <button
                  class="btn btn-light"
                  @click="
                    mostrar_clave_inalambrica = !mostrar_clave_inalambrica
                  "
                  :title="!mostrar_clave_inalambrica ? 'mostrar': 'ocultar'"
                >
                  <i class="mdi font-size-15 text-muted" :class="mostrar_clave_inalambrica ? 'mdi-eye-off-outline': 'mdi-eye-outline'"></i>
                </button>
                
              </div>
            </div>
          </div>
          <br>
          <h3>Credencial de administración</h3>

          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Usuario</label>
              <span class="form-control">{{punto_acceso.usuario}}</span>
              <br>
              <label>Clave</label>
              <div class="input-group">
                <span class="form-control">{{!mostrar_clave ? '*****' : punto_acceso.clave}}</span>
                <button
                  class="btn btn-light"
                  @click="
                    mostrar_clave = !mostrar_clave
                  "
                  :title="!mostrar_clave ? 'mostrar': 'ocultar'"
                >
                  <i class="mdi font-size-15 text-muted" :class="mostrar_clave ? 'mdi-eye-off-outline': 'mdi-eye-outline'"></i>
                </button>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <label>Nota de información complementaria</label>
              <span class="textarea form-control">{{punto_acceso.nota}}</span>
            </div>
          </div>
          <br>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PuntoAccesosSrv from '@/services/PuntoAccesosSrv.js'
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlInformacionAp',

  data() {
    return {
      punto_acceso: {
        id: null,
        en_linea: 0,
        estado: 'Funcional', 
        marca: '',
        modelo: '',
        id_sitio: null,
        ip: '',
        latitud: null,
        longitud: null,
        n_clientes: 0,
        nombre: '',
        nota: '',
        ssid: '',
        ultimo_contacto: null,
        uptime: null,
        usuario: "",
        clave: '',
        clave_inalambrica: '',
        sitio:{
          nombre: ''
        },
        router: {
          nombre: ''
        }
      },
      id_punto_acceso: null,
      mostrar_clave_inalambrica: false,
      mostrar_clave: false
    };
  },

  methods: {
    mostrar(punto_acceso) {
      var self = this
      self.id_punto_acceso = punto_acceso.id
      self.cargarPuntoAcceso()
      
      var modal = new Modal(this.$refs.mdlInformacionAP)
      modal.show()
    },

    cargarPuntoAcceso: function() {
      let self = this

      let idPuntoAcceso = self.id_punto_acceso

      let params = {
        con_sitio: true,
        con_router: true
      }

      PuntoAccesosSrv.puntoAccesoJSON(idPuntoAcceso, params).then(response => {
        self.punto_acceso = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el punto de acceso '+ idPuntoAcceso
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
  },
};
</script>

<style scoped>
.textarea {
  height: auto;
  min-height: 110px;
}

span {
  height: auto;
  min-height: 34px;
}
</style>